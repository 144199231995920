@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1536px] px-4;
  }
}

@layer utilities {
  .pause {
    animation-play-state: paused;
  }
}

html,
body,
* {
  font-family: Roboto, sans-serif;
}

*:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #dfdede;
}

::-webkit-scrollbar-thumb {
  background: #999;
}

::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.section-title-left {
  /* border-color: transparent transparent rgb(228, 141, 141) rgb(228, 141, 141);
  border-style: solid;
  border-width: 26px 26px 26px 26px;
  height: 0px;
  width: 0px; */
  width: 60px;
  height: 52px;
  clip-path: polygon(0 0, 55% 0, 100% 100%, 0% 100%);
  background: #b7070a;
}
.section-title-outside {
  width: 60px;
  height: 52px;
  background: rgba(202, 26, 26, 0.3);
  clip-path: polygon(0 0, 55% 0, 100% 100%, 44% 100%);
}
